import React from 'react';
import { Button, Col } from 'reactstrap';
import * as Yup from 'yup';
import {
    CREATE_FORM_URL, DEFAULT_PAYMENT_OPTION, DRAFT_SERVER_URL, FORM_TYPE, GENERAL_INFORMATION_FORMTYPE, GENERAL_INFORMATION_VALIDFORMTYPE,
    GET_DRAFT_FORM_URL, GET_FEES_LICENCES_URL, GET_FORM_URL, GET_PROFILE_URL, HOME_PATH, LAYOUT_TYPE, PAYMENT_OPTIONS, SEARCH_ALL_LICENCE_PATH,
    TABLE_LAYOUT, GET_CODE_BY_CODE_CATEGORY, GET_APPLICATION_CONFIGURATION_BY_CATEGORY
} from '../../App/AppSettings';
import LicenceSummary from '../../Content/FormComponents/LicenceSummary';
import PaymentSummary from '../../Content/FormComponents/PaymentSummary';
import ReviewAndSubmit from '../../Content/FormComponents/ReviewAndSubmit';
import SupportingDocuments from '../../Content/FormComponents/SupportingDocuments';
import corporateData from '../../Data/temp-corporate-gi.json';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { AuthConsumer } from '../../Shared/Authentication/AuthContext';
import AuthHelper from '../../Shared/Authentication/AuthHelper';
import { BLS_FORM_APP_TYPES, BUTTON_TYPE, DELETE_FILES_ARRAY, SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ScrollToTop, SMARTForm, withDynaFormWrapper } from '../../Shared/Forms';
import { toastError } from '../../Shared/Forms/Toaster';
import history from '../../Shared/History';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import PageFooter from '../../Shared/PageFooter';
import CommonInformation from '../FormComponents/CommonInformation';
import { getRemoteList, getTableListValues } from '../FormComponents/FormUtils';
import { GeneralInformationComponent } from '../FormComponents/GeneralInformation/GeneralInformationComponent';
import { getAddressTypeOptions, setGeneralInfoValues } from '../FormComponents/GeneralInformation/Utils.js';
import {
    createValidationForCommonInfo, createValidationSchemaForDeclaration, createValidationSchemaForGeneralInfo,
    createValidationSchemaForLicenceFields, createValidationSchemaForSupportingDocuments,
    createDraftValidationSchemaForLicenceFields
} from '../FormComponents/LicenceApplicationInformation/ValidationSchema';
import LicenceInformation from '../FormComponents/LicenceInformation';
import { withTranslation } from 'react-i18next';
import utils from 'formiojs/utils';
import { getLicenceTypeNameTranslationKey, getLangKey } from '../../Content/DisplayComponents/DisplayUtils';
import { ERROR, LANGUAGE_KEYS, WIZARD_TAB_NAMES } from '../../Shared/Constants/LanguageKeys';
import { Form, Formio, form } from '@formio/react';
import 'bootstrap/dist/css/bootstrap.css';
import 'formiojs/dist/formio.full.css';
import '../../Css/formio.custom.css';
import { animateScroll as scroll } from 'react-scroll';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;
const FORM_CONTEXT = 'DevForm';

const DEFAULT_PAYMENT = { PaymentOption: DEFAULT_PAYMENT_OPTION };
// Navigation URL
const PREVIEW_URL = '/services/devform/preview';
const NAVIGATE_URL = PREVIEW_URL;
const RETRIEVE_URL = GET_FORM_URL;
const DRAFT_URL = DRAFT_SERVER_URL;

const FORMIO_SELECT_INPUT_TYPE = 'select';
const FORMIO_PANEL_INPUT_TYPE = 'panel';
const FORMIO_SELECT_DATA_SOURCE_CUSTOM = 'custom';
const FORMIO_DSP = 'DSP';
const FORMIO_DSP_CODE = 'Code';
const FORMIO_DSP_APPLICATION_CONFIGURATION = 'ApplicationConfiguration';

let generalInformationType = '';
let isCorporateProfile;
let corporateUEN;

// Auth
AuthHelper.getUser(user => {
    if (user !== undefined && user !== null) {
        isCorporateProfile = (user.profile.uen !== undefined);
        if (isCorporateProfile) {
            corporateUEN = user.profile.uen;
        }

        switch (isCorporateProfile) {
            case true:
                generalInformationType = GENERAL_INFORMATION_FORMTYPE.CORPORATE;
                break;
            case false:
            default:
                generalInformationType = GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL;
                break;
        }
    }
});
// Auth

const withLicenceContext = WrappedComponent => {
    class LicenceContext extends React.Component {
        render() {
            return (
                <AuthConsumer>
                    {({ profile }) => (
                        <LicenceCartContext.Consumer>
                            {({ currentCart }) => (
                                <WrappedComponent
                                    context={{ currentCart }}
                                    profile={profile}
                                    {...this.props}
                                />
                            )}
                        </LicenceCartContext.Consumer>
                    )}
                </AuthConsumer>
            );
        }
    }

    return LicenceContext;
};


class DevForm extends React.Component {

    constructor(props) {
        super(props);
        this.formIOSectionRef = React.createRef();
        this.state = {
            isDraft: false,
            devData: {},
            licenceFees: [],
            totalLicenceFee: '',
            formNames: '',
            validationSchema: '',
            FORM: {
                FORM_CONTEXT,
                FORM_TYPE: FORM_TYPE.NEW,
                FORM_INITIAL_VALUES: {}
            },
            paymentOption: DEFAULT_PAYMENT,
            selectedIdTypeArr: [],
            idNumberArr: [],
            isIdNumbersValid: true,
            idNumberValidationError: '',
            selectedCountries: [],
            addressTypeOptions: getAddressTypeOptions(),
            optionDataList: {},
            onlineBtn: BUTTON_TYPE.ACTIVE,
            offlineBtn: BUTTON_TYPE.INACTIVE,
            // form.io data to be sent to public API
            formIODataArray: [],
            // final form.io data converted
            formIODataArrayConverted: '',
            isUsingFormIO: false,
            // display name of form.io form on the application wizard
            formIO_SectionName: '',
            // array to hold all form.io forms
            formIOFormsList: [],
            formIOFormInstances: [],
            // json of temporary form.io form to hold common components
            commonFormIOJson: {},
            commonFormIOExist: false,
            commonFormInstance: null,
            commonFormIOData: ''
        };
    }

    async componentDidMount() {

        const { loader, t } = this.props;

        loader.start();

        const { state } = history.location;
        //form is a draft
        if (state && state.ID) {
            // Retrieve response from backend
            const response = await fetchRequest(GET_DRAFT_FORM_URL + state.ID, getParams(), false);
            await this.parseDraftResponse(response);
        } else {
            //if licenceCart is empty, send the user back to search all licence
            if (this.props.context.currentCart === undefined || this.props.context.currentCart.length === 0) {
                navigateTo(SEARCH_ALL_LICENCE_PATH);
            }
            //licenceCart is not empty
            else {
                let FETCH_DYNA_FORM_JSON = RETRIEVE_URL;
                this.props.context.currentCart.forEach(licence => {
                    FETCH_DYNA_FORM_JSON += `licenceId=${licence.Id}&`;
                });
                FETCH_DYNA_FORM_JSON += 'generalInformationType=' + generalInformationType;
                //get dynaFormJson from API
                const response = await fetchRequest(FETCH_DYNA_FORM_JSON, getParams(), false);

                if (response.success && response.body) {
                    const { IsSuccess, Data, Messages } = response.body;
                    if (IsSuccess) {
                        const jsonData = Data;
                        //used to populate payment page
                        this.getFormFees(jsonData.FormDefinitions);
                        await this.getOptionsDataList(jsonData);
                        this.setState({
                            isDraft: false,
                            devData: jsonData,
                            formNames: this.getFormNames(jsonData, false),
                            validationSchema: this.createValidationSchema(jsonData, generalInformationType),
                            draftValidationSchema: this.createDraftValidationSchema(jsonData),
                            FORM: {
                                FORM_CONTEXT,
                                FORM_TYPE: FORM_TYPE.NEW,
                                FORM_INITIAL_VALUES: this.getFormInitialValues(jsonData)
                            }
                        }, async () => {
                            this.setState(
                                { values: Object.assign({}, this.state.FORM.FORM_INITIAL_VALUES, this.state.paymentOption) }, () => this.getProfile()
                            );
                            loader.done();
                        });
                    }
                    else {
                        loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages))
                        );
                    }
                } else {
                    loader.error(t(ERROR.CANNOT_REACH_SERVER));
                }
            }
        }
        if (this.state.isUsingFormIO) {
            for (var currentForm of this.state.formIOFormsList) {
                const response = await fetchRequest(currentForm.url, getParams(), false);
                const apiFetchResultArray = [];
                if (response.success && response.body !== "") {
                    var json = response.body;
                    // replace each field's title/label with the translation key
                    if (json.components) {
                        utils.eachComponent(json.components, (component) => {
                            const translated = t(getLangKey(LANGUAGE_KEYS.BLS_FORMIO_FIELD, currentForm.translationKey, component.key))
                            if (utils.isLayoutComponent(component)) {
                                component.title = translated;
                            }
                            if (utils.isInputComponent(component)) {
                                component.label = translated;
                            }
                        }, true);
                    }

                    // fetch Code/ApplicationConfiguration from DB
                    const selectComponents = utils.searchComponents(json.components, {
                        'type': FORMIO_SELECT_INPUT_TYPE,
                        'dataSrc': FORMIO_SELECT_DATA_SOURCE_CUSTOM
                    });

                    // need to fetch values from Code/ApplicationConfiguration separately since form.io eachComponent function does not support async
                    for (const selectComponent of selectComponents) {
                        const customDataSource = selectComponent.data.custom.split('.');
                        if (customDataSource[0] === FORMIO_DSP) {
                            switch (customDataSource[1]) {
                                case FORMIO_DSP_CODE:
                                    const queryString = Object.assign({}, { '?categories': customDataSource[2] });
                                    const codeResponse = await fetchRequest(GET_CODE_BY_CODE_CATEGORY, getParams(queryString), false);
                                    if (codeResponse.success) {
                                        apiFetchResultArray.push({ key: selectComponent.key, value: codeResponse.body.Data });
                                    }
                                    break;
                                case FORMIO_DSP_APPLICATION_CONFIGURATION:
                                    const queryString_two = Object.assign({}, { '?category': customDataSource[2] });
                                    const applicationConfigurationResponse = await fetchRequest(GET_APPLICATION_CONFIGURATION_BY_CATEGORY, getParams(queryString_two), false);
                                    if (applicationConfigurationResponse.success) {
                                        apiFetchResultArray.push({ key: selectComponent.key, value: applicationConfigurationResponse.body.Data });
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    }

                    utils.eachComponent(json.components, (component) => {
                        if (component.type === FORMIO_SELECT_INPUT_TYPE && component.dataSrc === FORMIO_SELECT_DATA_SOURCE_CUSTOM) {
                            const customDataSource = component.data.custom.split('.');
                            if (customDataSource[0] === FORMIO_DSP) {
                                component.dataSrc = 'values';
                                component.data.custom = '';
                                const resultArray = apiFetchResultArray.find(item => item.key === component.key);
                                switch (customDataSource[1]) {
                                    case FORMIO_DSP_CODE:
                                        component.data.values = resultArray.value.map((item) => ({
                                            'value': item.CodeValue,
                                            'label': item.CodeTitle
                                        }));
                                        break;
                                    case FORMIO_DSP_APPLICATION_CONFIGURATION:
                                        component.data.values = resultArray.value.map((item) => ({
                                            'value': item.ConfigurationValue,
                                            'label': item.ConfigurationName
                                        }));
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }, true);
                    currentForm.json = json;
                }

                const newFormIOFormsList = this.state.formIOFormsList.map((oldForm) => {
                    if (oldForm.name === currentForm.name)
                        return currentForm;
                    else
                        return oldForm;
                });
                this.setState({ formIOFormsList: newFormIOFormsList });
            }
            this.formIO_CombineCommonComponents();
            Formio.setProjectUrl(this.state.formIOFormsList[0].url.split('/').slice(0, 4).join('/'));
        }
    }

    //parse Draft form from server
    parseDraftResponse = async response => {
        const { state } = history.location;
        const { loader, t } = this.props;

        if (response.success && response.body) {
            const { IsSuccess, Data, Messages } = response.body;
            if (IsSuccess) {
                const jsonData = Data.FormDefinitonJson;
                this.getFormFees(jsonData.FormDefinitions);

                const draftData = this.getDraftFormValues(jsonData, Data.FormData, Data.FileKeyIDList);
                await this.getOptionsDataList(jsonData);
                this.setState(
                    {
                        isDraft: true,
                        devData: jsonData,
                        formNames: this.getFormNames(jsonData, true),
                        validationSchema: this.createValidationSchema(jsonData, generalInformationType),
                        draftValidationSchema: this.createDraftValidationSchema(jsonData),
                        FORM: {
                            FORM_CONTEXT,
                            FORM_TYPE: FORM_TYPE.NEW,
                            FORM_INITIAL_VALUES: this.getFormInitialValues(jsonData)
                        }
                    },
                    () => {
                        //insert draft data into form
                        this.setState({
                            values: Object.assign({}, this.state.FORM.FORM_INITIAL_VALUES, draftData, this.state.paymentOption,
                                {
                                    FormID: state.ID,
                                    [DELETE_FILES_ARRAY]: []
                                })
                        }, () => loader.done());
                    });
            }
            else {
                // Error messages to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages))
                );
            }
        }
        else {
            // Error messages to be loaded here
            loader.error(t(ERROR.DRAFT_RETRIEVAL_FAIL));
        }
    };

    /************************/
    /*** Me Profile Utils ***/
    /************************/

    async getProfile() {
        try {
            const { loader, t } = this.props;
            let response = null;
            //Start loading
            loader.start();
            if (isCorporateProfile) {
                // response = await fetchRequest(GET_PROFILE_URL + this.props.profile.userguid, getParams(), false);
                // TODO: change to check for UEN in token *CorporateGI_TODO*
                response = corporateData;
            }
            else {
                response = await fetchRequest(GET_PROFILE_URL + this.props.profile.userguid, getParams(), false);
            }
            // End loading
            loader.done();
            const { IsSuccess, Data, Messages } = response.body;
            if (response.success && IsSuccess) {
                const newValues = Object.assign({}, this.state.values, {
                    GeneralInformation: setGeneralInfoValues(Data, isCorporateProfile, corporateUEN)
                });

                const newTranslatedValues = await this.setGeneralInformationCodeName(newValues, this.state.optionDataList)
                this.setState({
                    values: newTranslatedValues
                });
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } catch (error) {
            toastError(error);
        }
    }

    setGeneralInformationCodeName = async (data, options) => {
        const generalInformation = data.GeneralInformation;
        const { GI_Salutation, GI_Nationality, GI_IDtype } = generalInformation;
        const result = {
            GI_Salutation: '',
            GI_Nationality: '',
            GI_IDtype: ''
        }

        if (GI_Salutation != null) {
            const salutation = options.Salutation.find(d => d.label === GI_Salutation);
            if (salutation !== undefined) {
                result.GI_Salutation = salutation.value
            }
        }

        if (GI_Nationality !== null) {
            const nationality = options.Country.find(d => d.label === GI_Nationality);
            if (nationality !== undefined) {
                result.GI_Nationality = nationality.value
            }
        }

        if (GI_IDtype !== null) {
            const idType = options.IdType.find(d => d.label === GI_IDtype);
            if (idType !== undefined) {
                result.GI_IDtype = idType.value
            }
        }

        const newGeneralInformation = { ...generalInformation, ...result }

        return { ...data, GeneralInformation: newGeneralInformation };
    }



    getOptionsDataList = async devData => {
        const { t } = this.props;
        const listOfCategory = [];
        //loop through all the fields to collate all the options required for the entire form

        //GeneralDefinition
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(field.DataParameters.Category) < 0) {
                    listOfCategory.push(field.DataParameters.Category);
                }
            });
        });

        //FormDefinition
        devData.FormDefinitions.forEach(formDef => {
            if (formDef.Sections != null && formDef.Sections != undefined) {
                formDef.Sections.forEach(formDefSection => {
                    formDefSection.Fields && formDefSection.Fields.forEach(formDefField => {
                        const isRemote = formDefField.DataSource !== undefined && formDefField.DataSource === 'Remote';
                        if (isRemote && listOfCategory.indexOf(formDefField.DataParameters.Category) < 0) {
                            listOfCategory.push(formDefField.DataParameters.Category);
                        }
                    });
                    formDefSection.Table && formDefSection.Table.Fields.forEach(formDefTableField => {
                        const isRemote = formDefTableField.DataSource !== undefined && formDefTableField.DataSource === 'Remote';
                        if (isRemote && listOfCategory.indexOf(formDefTableField.DataParameters.Category) < 0) {
                            listOfCategory.push(formDefTableField.DataParameters.Category);
                        }
                    });
                });
            }
        });

        //CommonDefinitions
        for (const commonSection in devData.CommonDefinitions.FieldsDefinition) {
            if (devData.CommonDefinitions.FieldsDefinition[commonSection] && devData.CommonDefinitions.FieldsDefinition[commonSection].Fields.length > 0) {
                for (const fieldIndex in devData.CommonDefinitions.FieldsDefinition[commonSection].Fields) {
                    const field = devData.CommonDefinitions.FieldsDefinition[commonSection].Fields[fieldIndex];
                    const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
                    if (isRemote && listOfCategory.indexOf(field.DataParameters.Category) < 0) {
                        listOfCategory.push(field.DataParameters.Category);
                    }
                }
            }
        }

        let totalOptionsList = null;
        if (listOfCategory.length !== 0) {
            totalOptionsList = await getRemoteList(t, listOfCategory);
        }

        this.setState({
            optionDataList: totalOptionsList
        });
    };

    choosePaymentOption = (paymentOption, values) => {
        values.PaymentOption = paymentOption;

        if (paymentOption === PAYMENT_OPTIONS.ONLINE) {
            this.setState({
                onlineBtn: BUTTON_TYPE.ACTIVE,
                offlineBtn: BUTTON_TYPE.INACTIVE
            });
        } else {
            this.setState({
                onlineBtn: BUTTON_TYPE.INACTIVE,
                offlineBtn: BUTTON_TYPE.ACTIVE
            });
        }
    };

    /****************************/
    /*** Me Profile Utils End ***/
    /****************************/


    getFormInitialValues = devData => {
        let generalInformationType = '';

        const formTypeNames = {
            FormTypes: []
        };

        const generalFieldNames = {
            GeneralInformation: {}
        };
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                generalFieldNames.GeneralInformation[field.Name] = '';
            });
        });

        const commonFieldNames = {
            CommonInformation: {}
        };
        devData.CommonDefinitions.FieldsDefinition.forEach(section => {
            section.Fields.forEach(field => {
                commonFieldNames.CommonInformation[field.Name] = '';
            });
        });

        const supportingDocumentNames = {
            SupportingDocument: {}
        };
        devData.CommonDefinitions.FilesDefinition.Fields.forEach(field => {
            supportingDocumentNames.SupportingDocument[field.Name] = [];
        });

        const licenceFieldNames = {};
        devData.FormDefinitions.forEach(formDefinition => {
            formTypeNames.FormTypes.push(formDefinition.FormType);
            licenceFieldNames[formDefinition.FormType] = {};
            if (formDefinition.Sections != null) {
                formDefinition.Sections.forEach(section => {
                    section.Fields && section.Fields.forEach(field => {
                        licenceFieldNames[formDefinition.FormType][field.Name] = '';
                    });

                    if (section.Table != null) {
                        licenceFieldNames[formDefinition.FormType][section.Name] = [];
                        const newTableArrObj = {};
                        section.Table.Fields.forEach(field => {
                            newTableArrObj[field.Name] = '';
                        });
                        if (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) {
                            licenceFieldNames[formDefinition.FormType][section.Name].push(newTableArrObj);
                        }
                    }
                });
            }
            if (formDefinition.FormIOUrl != null) {
                this.formIO_SetFormIOStateValues(formDefinition, null, null);
            }
        });

        formTypeNames.FormTypes.push(GENERAL_INFORMATION_VALIDFORMTYPE);
        (isCorporateProfile ?
            generalInformationType = GENERAL_INFORMATION_FORMTYPE.CORPORATE
            :
            generalInformationType = GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL
        );

        return {
            ...generalFieldNames, ...licenceFieldNames, ...commonFieldNames, ...supportingDocumentNames, ...formTypeNames,
            GeneralInformationType: generalInformationType
        };
    };

    getDraftFormValues = (devData, formData, fileKeyIDList) => {
        //dataList is used for common data
        //puts all the form data into a big list so that common licence can find it
        let dataList;
        for (const form in formData.DynaForms) {
            if (formData.DynaForms[form].DataList) {
                dataList = Object.assign({}, dataList, formData.DynaForms[form].DataList);
            }
        }

        let generalInformationType = '';
        const formTypeNames = {
            FormTypes: formData.FormTypes
        };
        const licenceFieldNames = {};
        formData.DynaForms.forEach(form => {
            const tableValueKeys = Object.keys(form.DataList);
            devData.FormDefinitions.forEach(formDefinition => {
                if (form.FormType === formDefinition.FormType) {
                    licenceFieldNames[formDefinition.FormType] = {};
                    if (formDefinition.Sections != null) {
                        formDefinition.Sections.forEach(section => {
                            section.Fields && section.Fields.forEach(field => {
                                if (form.DataList[field.Name] !== undefined && form.DataList[field.Name] !== '') {
                                    licenceFieldNames[formDefinition.FormType][field.Name] = form.DataList[field.Name];
                                }
                                else {
                                    licenceFieldNames[formDefinition.FormType][field.Name] = '';
                                }
                            });

                            if (section.Table) {
                                licenceFieldNames[formDefinition.FormType][section.Name] = [];
                                const sectionTableList = getTableListValues(tableValueKeys, section.Name);

                                sectionTableList && sectionTableList[section.Name].forEach((row, rindex) => {
                                    const newTableArrObj = {};
                                    section.Table.Fields && section.Table.Fields.forEach(field => {
                                        if (form.DataList[`${section.Name}.${rindex}.${field.Name}`] !== undefined && form.DataList[`${section.Name}.${rindex}.${field.Name}`] !== '') {
                                            newTableArrObj[field.Name] = form.DataList[`${section.Name}.${rindex}.${field.Name}`];
                                        }
                                        else {
                                            newTableArrObj[field.Name] = '';
                                        }
                                    });
                                    licenceFieldNames[formDefinition.FormType][section.Name].push(newTableArrObj);
                                });
                            }
                        });
                    } else {
                        // add the draft data to form.io forms
                        licenceFieldNames[formDefinition.FormType] = form.DataList;
                    }
                }
                if (formDefinition.FormIOUrl != null) {
                    this.formIO_SetFormIOStateValues(formDefinition, form.DataList, form.FormType);
                }
            });
        });

        const generalFieldNames = {};
        const GI = GENERAL_INFORMATION_VALIDFORMTYPE;
        generalFieldNames[GI] = {};
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                if (formData.GeneralForm.DataList[field.Name] !== undefined && formData.GeneralForm.DataList[field.Name] !== '') {
                    generalFieldNames[GI][field.Name] = formData.GeneralForm.DataList[field.Name];
                }
                else {
                    generalFieldNames[GI][field.Name] = '';
                }
            });
        });

        const commonFieldNames = {
            CommonInformation: {}
        };
        devData.CommonDefinitions.FieldsDefinition.forEach(section => {
            section.Fields.forEach(field => {
                if (dataList[field.Name] !== undefined && dataList[field.Name] !== '') {
                    commonFieldNames.CommonInformation[field.Name] = dataList[field.Name];
                }
                else {
                    commonFieldNames.CommonInformation[field.Name] = '';
                }
            });
        });

        const supportingDocumentNames = {
            SupportingDocument: {}
        };
        devData.CommonDefinitions.FilesDefinition.Fields.forEach(field => {
            const fileList = [];
            fileKeyIDList.forEach(file => {
                if (file.FileUploadField === field.Name) {
                    fileList.push({ fileName: file.FileKey, file: null, fileKey: file.FileKey, fileID: file.DynaFormDocumentIds });
                }
            });
            supportingDocumentNames.SupportingDocument[field.Name] = fileList;
        });

        formTypeNames.FormTypes.push(GENERAL_INFORMATION_VALIDFORMTYPE);

        (isCorporateProfile ?
            generalInformationType = GENERAL_INFORMATION_FORMTYPE.CORPORATE
            :
            generalInformationType = GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL
        );

        return {
            ...generalFieldNames, ...licenceFieldNames, ...commonFieldNames, ...supportingDocumentNames, ...formTypeNames,
            GeneralInformationType: generalInformationType
        };
    };

    async getFormFees(formDefinitions) {
        let REQUEST_URL = GET_FEES_LICENCES_URL;
        REQUEST_URL += 'feeType=' + BLS_FORM_APP_TYPES.APPLY;
        formDefinitions.forEach(form => {
            REQUEST_URL += `&formTypes=${form.FormType}&`;
        });

        const response = await fetchRequest(REQUEST_URL, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            const isDataPresent = Data && Data.LicenceFeeItemList && Data.LicenceFeeItemList.length > 0;
            this.setState({
                licenceFees: isDataPresent ? Data.LicenceFeeItemList : [],
                totalLicenceFee: isDataPresent ? Data.TotalLicenceFee : ''
            });
        }
    }

    getFormNames = (devData, isDraft) => {
        const { t } = this.props;
        const { FieldsDefinition, FilesDefinition } = devData.CommonDefinitions;
        //Check if common fields exist
        const commonFormExist = FieldsDefinition !== undefined && FieldsDefinition[0] !== undefined &&
            FieldsDefinition[0].Fields !== undefined && FieldsDefinition[0].Fields.length > 0;
        const supportingdocExist = FilesDefinition !== undefined && FilesDefinition.Fields.length > 0;
        // Specific Forms
        const formNames = devData.FormDefinitions.map(formDefinition => formDefinition.FormName);
        const result = {};
        // Have to add in order
        // First add General Information + Licence Common
        result[t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)] = t(WIZARD_TAB_NAMES.GENERAL_INFORMATION);

        if (commonFormExist) {
            result[t(WIZARD_TAB_NAMES.COMMON_INFORMATION)] = t(WIZARD_TAB_NAMES.COMMON_INFORMATION);
        }
        if (isDraft) {
            devData.FormDefinitions.forEach((licence, i) => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.LicenceTypeName);
                result[formNames[i]] = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.LicenceTypeId));
            });
        }
        else {
            this.props.context.currentCart.forEach((licence, i) => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);
                result[formNames[i]] = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id));
            });
        }
        // Lastly add the review panel
        if (supportingdocExist) {
            result[t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)] = t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS);
        }
        result[t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)] = t(WIZARD_TAB_NAMES.REVIEW_INFORMATION);
        result[t(WIZARD_TAB_NAMES.PAYMENTS)] = t(WIZARD_TAB_NAMES.PAYMENTS);

        return result;
    };

    createValidationSchema = (devData, generalInformationType) => {
        const { t } = this.props;
        const { FieldsDefinition, FilesDefinition } = devData.CommonDefinitions;
        const validationObject = {};

        /***********************/
        /*** General Section ***/
        /***********************/
        validationObject.GeneralInformation = createValidationSchemaForGeneralInfo(t, generalInformationType);

        /******************************/
        /*** Licence Common Section ***/
        /******************************/
        validationObject.CommonInformation = createValidationForCommonInfo(t, FieldsDefinition);

        /***********************/
        /*** Licence Section ***/
        /***********************/
        devData.FormDefinitions.forEach(formDefinition => {
            if (formDefinition.Sections != null) {
                validationObject[formDefinition.FormType] = createValidationSchemaForLicenceFields(t, formDefinition);
            }
        });

        /***********************************/
        /*** Supporting Document Section ***/
        /***********************************/
        validationObject.SupportingDocument = createValidationSchemaForSupportingDocuments(t, FilesDefinition.Fields);

        /***************************/
        /*** Declaration Section ***/
        /***************************/
        validationObject.DeclarationAgreement = createValidationSchemaForDeclaration(t);
        return Yup.object().shape(validationObject);
    };

    createDraftValidationSchema = devData => {
        const { t } = this.props;
        const validationObject = {};

        devData.FormDefinitions.forEach(formDefinition => {
            if (formDefinition.Sections != null) {
                //used for dynamic table with modal pop up
                //ensures that table will have validation after draft submission
                validationObject[formDefinition.FormType] = createDraftValidationSchemaForLicenceFields(t, formDefinition);
            }
        });

        return Yup.object().shape(validationObject);
    };

    displayGeneralInformationReview = (generalInfo, values) =>
        <LicenceSummary
            formName={generalInfo.FormName}
            valuesFormInfo={values[generalInfo.FormType]}
            formSections={generalInfo.Sections}
            key={generalInfo.FormName}
            commonFieldList={[]}
            documentList={[]}>
        </LicenceSummary>;

    formIO_ChangeHandler = (formData, index) => {
        if (this.formIOSectionRef != null && this.formIOSectionRef != undefined) {
            this.setState({ formIO_SectionName: this.formIOSectionRef.props.sectionName })
        }
        const currentForm = this.state.formIOFormsList[index];
        currentForm.isValid = formData.isValid;
        console.log(formData);

        // assign data of current form into formIODataArray
        var formIOName = this.state.values.FormTypes.find((str) => str.includes(currentForm.name));
        if (this.state.formIODataArray !== undefined && this.state.formIODataArray.length > 0) {
            const currentFormIOData = this.state.formIODataArray.find((form) => form.name === formIOName);
            if (currentFormIOData) {
                const newFormIODataArray = this.state.formIODataArray.map((form) => {
                    if (form.name === formIOName)
                        return { name: formIOName, data: formData.data, isValid: formData.isValid };
                    else
                        return form;
                });
                this.setState({ formIODataArray: newFormIODataArray });
            } else {
                this.setState({ formIODataArray: [...this.state.formIODataArray, { name: formIOName, data: formData.data, isValid: formData.isValid }] });
            }
        } else {
            this.setState({ formIODataArray: [...this.state.formIODataArray, { name: formIOName, data: formData.data, isValid: formData.isValid }] });
        }
        this.formIO_ConvertDataArrayToJson();

        // assign new value of formIOFormsList
        const newFormList = this.state.formIOFormsList.map((form, i) => {
            if (i === index)
                return currentForm;
            else
                return form;
        });
        this.setState({ formIOFormsList: newFormList });
    }

    formIO_CommonInformation_ChangeHandler = (formData) => {
        const { t } = this.props;
        const commonFormName = t(WIZARD_TAB_NAMES.COMMON_INFORMATION);
        if (this.formIOSectionRef != null && this.formIOSectionRef != undefined) {
            this.setState({ formIO_SectionName: commonFormName })
        }
        if (this.state.formIODataArray !== undefined && this.state.formIODataArray.length > 0) {
            const currentFormIOData = this.state.formIODataArray.find((form) => form.name === commonFormName);
            if (currentFormIOData) {
                const newFormIODataArray = this.state.formIODataArray.map((form) => {
                    if (form.name === commonFormName)
                        return { name: commonFormName, data: formData.data, isValid: formData.isValid };
                    else
                        return form;
                });
                this.setState({ formIODataArray: newFormIODataArray });
            } else {
                this.setState({ formIODataArray: [...this.state.formIODataArray, { name: commonFormName, data: formData.data, isValid: formData.isValid }] });
            }
        } else {
            this.setState({ formIODataArray: [...this.state.formIODataArray, { name: commonFormName, data: formData.data, isValid: formData.isValid }] });
        }
        this.formIO_ConvertDataArrayToJson();
    }

    formIO_ValidateHandler = () => {
        for (const instance of this.state.formIOFormInstances) {
            instance.instance.checkValidity(null, true);
            this.formIOSectionRef.props.toggleSection(false, false, instance.name, null);
            scroll.scrollTo(this.formIOSectionRef);
        }

        if (this.state.commonFormInstance) {
            const { t } = this.props;
            this.state.commonFormInstance.checkValidity(null, true);
            this.formIOSectionRef.props.toggleSection(false, false, t(WIZARD_TAB_NAMES.COMMON_INFORMATION), null);
            scroll.scrollTo(this.formIOSectionRef);
        }
    }

    formIO_CollapseButtonClickHandler = (toCollapse, index) => {
        if (index >= 0) {
            var currentForm = this.state.formIOFormsList[index];
            var json = currentForm.json;
            if (json != null && json.components != null) {
                for (var i = 0; i < json.components.length; i++) {
                    var component = json.components[i];
                    component.collapsed = toCollapse;
                }
                currentForm.json = json;
                const newFormList = this.state.formIOFormsList.map((form, i) => {
                    if (i === index)
                        return currentForm;
                    else
                        return form;
                });
                this.setState({ formIOFormsList: newFormList });
                currentForm.formInstance.form = json;
                currentForm.formInstance.redraw();
            }
        }
        // for Common Information tab
        else {
            var json = this.state.commonFormIOJson;
            if (json != null && json.components != null) {
                for (var i = 0; i < json.components.length; i++) {
                    var component = json.components[i];
                    component.collapsed = toCollapse;
                }
                this.setState({ commonFormIOJson: json });
                this.state.commonFormInstance.form = json;
                this.state.commonFormInstance.redraw();
            }
        }
    }

    formIO_ReadyHandler = (formInstance, index) => {
        if (index >= 0) {
            var currentForm = this.state.formIOFormsList[index];
            currentForm.formInstance = formInstance;

            currentForm.formInstance.submission = {
                data: currentForm.data
            };
            const newFormList = this.state.formIOFormsList.map((form, i) => {
                if (i === index)
                    return currentForm;
                else
                    return form;
            });
            this.setState({ formIOFormsList: newFormList });
            const currentInstance = this.state.formIOFormInstances[index];
            if (!currentInstance) {
                this.setState({ formIOFormInstances: [...this.state.formIOFormInstances, { name: currentForm.name, instance: formInstance }] })
            }
        } else {
            let newInstance = formInstance;
            newInstance.submission = {
                data: this.state.commonFormIOData
            }
            this.setState({ commonFormInstance: newInstance });
        }
    }

    formIO_ConvertDataArrayToJson = () => {
        let resultJson = [];
        this.formIO_CopyCommonInformationToOriginalForms();
        for (const formData of this.state.formIODataArray) {
            const formName = formData.name;
            const currentFormData = {
                [formName]: formData.data
            }
            resultJson = [...resultJson, currentFormData];
        }
        console.log(this.state.formIOFormsList);
        console.log(this.state.formIODataArray);
        this.setState({ formIODataArrayConverted: Object.assign({}, ...resultJson) });
    }

    // find the common fields and sections between the form.io forms
    formIO_CombineCommonComponents = () => {
        let commonSectionList = [];
        let commonFieldList = [];
        // clone formIOFormsList into temp list so that manipulation of formIOFormsList state after componentDidMount does not affect the common field/section list
        // this is because normally the variable is passed by reference and not value
        let tempFormList = [];
        for (const form of this.state.formIOFormsList) {
            tempFormList = [...tempFormList, { name: JSON.parse(JSON.stringify(form.name)), json: JSON.parse(JSON.stringify(form.json)) }];
        }
        for (const form of tempFormList) {
            if (form.json) {
                const result = this.formIO_GetCommonSectionAndField(commonSectionList, commonFieldList, form.json, form.name);
                commonFieldList = result.commonFieldList;
                commonSectionList = result.commonSectionList;
            }
        }

        // remove fields that only appear once so the commonFieldList now only contains the duplicated fields between the forms
        const tempCommonFieldList = commonFieldList.filter((field) => field.formName.length > 1);
        const removedFieldList = commonFieldList.filter((field) => field.formName.length < 2);
        commonFieldList = tempCommonFieldList;

        const tempCommonSectionList = commonSectionList.filter((section) => section.formName.length > 1);
        const removedSectionList = commonSectionList.filter((section) => section.formName.length < 2);
        commonSectionList = tempCommonSectionList;

        // now need to remove the common field from original form json and remove the non-common field from the common section list
        this.formIO_RemoveCommonFieldFromOriginalJson(commonFieldList, removedSectionList);

        // now need to remove non-common field from the commonSectionList
        commonSectionList = this.formIO_RemoveNonCommonFieldFromCommonSection(commonSectionList, removedFieldList);

        this.formIO_SetCommonFormJson(commonSectionList);
    }

    // populate the common field and section list 
    formIO_GetCommonSectionAndField = (commonSectionList, commonFieldList, formJson, formName) => {
        const flattened = utils.flattenComponents(formJson.components, true);
        const flattenedArray = Object.entries(flattened);
        flattenedArray.forEach((individualComponent) => {
            if (individualComponent[1].type === FORMIO_PANEL_INPUT_TYPE) {
                commonSectionList = this.formIO_AddElementToCommonComponentList(commonSectionList, individualComponent[1].title, formName, individualComponent);
            } else {
                commonFieldList = this.formIO_AddElementToCommonComponentList(commonFieldList, individualComponent[1].label, formName, individualComponent);
            }
        });
        return { commonSectionList, commonFieldList };
    }

    formIO_AddElementToCommonComponentList = (commonComponentList, componentName, formName, component) => {
        const existingComponent = commonComponentList.find((component) => component.componentName === componentName);
        if (existingComponent) {
            existingComponent.formName = [...existingComponent.formName, { formName }];
            const newCommonComponentList = commonComponentList.map((component) => {
                if (component.componentName === existingComponent.componentName)
                    return existingComponent;
                else
                    return component;
            });
            commonComponentList = newCommonComponentList;
        } else {
            commonComponentList = [...commonComponentList, {
                formName: [{ formName }],
                componentName: componentName,
                component: component
            }];
        }
        return commonComponentList;
    }

    // set the final common sections list to the common form.io json
    formIO_SetCommonFormJson = (commonSectionList) => {
        const formIOCommonSectionList = commonSectionList.map((section) => {
            return section.component[1];
        })

        this.setState({
            commonFormIOJson: {
                components: formIOCommonSectionList
            }
        });

        const commonFormIOExist = formIOCommonSectionList !== undefined && formIOCommonSectionList.length > 0;
        this.setState({ commonFormIOExist: commonFormIOExist });
        if (this.state.commonFormIOExist) {
            // add the Common Information section to the form wizard
            const { t } = this.props;
            const formNames = Object.entries(this.state.formNames);
            const commonInformationTranslated = t(WIZARD_TAB_NAMES.COMMON_INFORMATION);
            const newFormNames = [...formNames.slice(0, 1), [commonInformationTranslated, commonInformationTranslated], ...formNames.slice(1)];
            this.setState({ formNames: Object.fromEntries(newFormNames) });
        }
    }

    formIO_RemoveCommonFieldFromOriginalJson = (commonFieldList, removedSectionList) => {
        let newFormIOFormsList = this.state.formIOFormsList;
        newFormIOFormsList.forEach((form) => {
            for (const field of commonFieldList) {
                let json = form.json;
                json.components = json.components.filter((component) => component.label !== field.componentName);
                json.components.forEach((component) => {
                    // if panel does not belong to the common information tab, then ignore all fields under this panel
                    const existingRemovedSection = removedSectionList.find((section) => section.componentName === component.title);
                    if (existingRemovedSection)
                        return;
                    if (component.components && component.components.length > 0) {
                        component.components = component.components.filter((innerCommponent) => innerCommponent.label !== field.componentName);
                    }
                });
            }

            // remove panel without any field
            if (form.json.components) {
                form.json.components = form.json.components.filter((component) => component.type === FORMIO_PANEL_INPUT_TYPE &&
                    component.components &&
                    component.components.length > 0);
            }
        });

        this.setState({ formIOFormsList: newFormIOFormsList });
    }

    formIO_RemoveNonCommonFieldFromCommonSection = (commonSectionList, removedFieldList) => {
        for (const field of removedFieldList) {
            commonSectionList.forEach((section) => {
                section.component[1].components = section.component[1].components.filter((component) => component.label !== field.componentName);
            });
        }
        return commonSectionList;
    }

    formIO_CopyCommonInformationToOriginalForms = () => {
        const { t } = this.props;
        const commonFormName = t(WIZARD_TAB_NAMES.COMMON_INFORMATION);
        const commonInformationData = this.state.formIODataArray.find((form) => form.name === commonFormName);
        const nonCommonInfoData = this.state.formIODataArray.filter((form) => form.name !== commonFormName);

        if (commonInformationData) {
            nonCommonInfoData.forEach((form) => {
                const newData = Object.assign({}, form.data, commonInformationData.data);
                form.data = newData;
            });
        }
    }

    formIO_ValidateAllForms = () => {
        let result = true;
        for (const form of this.state.formIODataArray) {
            result &= form.isValid
        }
        return result;
    }

    formIO_SetFormIOStateValues = (formDefinition, dataList, formType) => {
        this.setState({
            isUsingFormIO: true,
            commonFormIOExist: false
        });

        let dataGridDataArray = [];
        let nonDataGridArray = [];
        for (const individualFieldKey in dataList) {
            const fieldKeyArray = individualFieldKey.split('.');
            if (fieldKeyArray.length < 2) {
                nonDataGridArray = [...nonDataGridArray, individualFieldKey];
                continue;
            }

            const datagridName = fieldKeyArray[0];
            const newFieldKey = fieldKeyArray[fieldKeyArray.length - 1];

            let existingDataGridElement = dataGridDataArray.find(element => element[0] === datagridName);
            if (existingDataGridElement) {
                let existingDataGridValue = existingDataGridElement[1][fieldKeyArray[1]];
                if (existingDataGridValue) {
                    existingDataGridValue[newFieldKey] = dataList[individualFieldKey];
                } else {
                    const newDataGridValue = {
                        [newFieldKey]: dataList[individualFieldKey]
                    };
                    existingDataGridElement[1] = [...existingDataGridElement[1], newDataGridValue];
                }
            } else {
                const newDataGridElement = [datagridName, [{
                    [newFieldKey]: dataList[individualFieldKey]
                }]];
                dataGridDataArray = [...dataGridDataArray, newDataGridElement];
            }
        }

        let formData;
        for (const individualGrid of dataGridDataArray) {
            formData = {
                ...formData,
                [individualGrid[0]]: individualGrid[1]
            }
        }

        for (const individualField of nonDataGridArray) {
            formData = {
                ...formData,
                [individualField]: dataList[individualField]
            }
        }

        if (this.state.formIOFormsList.filter((form) => form.name === formDefinition.FormName).length === 0) {
            this.setState({
                formIOFormsList: [...this.state.formIOFormsList, {
                    url: formDefinition.FormIOUrl,
                    name: formDefinition.FormName,
                    json: {},
                    translationKey: formDefinition.FormName.replaceAll(' ', '_'),
                    isValid: true,
                    formInstance: null,
                    data: formData
                }],
                commonFormIOData: formData
            });
        } else {
            if (formType) {
                let currentForm = this.state.formIOFormsList.find((form) => formType.includes(form.name));
                currentForm.data = formData;

                const newFormList = this.state.formIOFormsList.map((form) => {
                    if (form.name === currentForm.name)
                        return currentForm;
                    else
                        return form;
                });
                this.setState({ formIOFormsList: newFormList });
            }
        }
    }

    render() {
        const { t } = this.props;
        if (!this.state.values) {
            return null;
        } else {
            const GeneralInformationComponentInput = GeneralInformationComponent[generalInformationType];
            const { FieldsDefinition, FilesDefinition } = this.state.devData.CommonDefinitions;
            const declarations = this.state.devData.DeclarationDefinitions.Declarations;
            const { formNames } = this.state;
            const { optionDataList } = this.state;
            const { devData } = this.state;
            const commonFieldsExist = this.state.commonFormIOExist ||
                (FieldsDefinition && FieldsDefinition[0] && FieldsDefinition[0].Fields && FieldsDefinition[0].Fields.length > 0);
            const formLanguageKeys = [];
            if (this.state.isDraft) {
                devData.FormDefinitions.forEach((licence, i) => {
                    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.LicenceTypeName);
                    formLanguageKeys.push(t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.LicenceTypeId)));
                });
            }
            else {
                this.props.context.currentCart.forEach(licence => {
                    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);
                    formLanguageKeys.push(t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id)));
                });
            }

            return (
                <React.Fragment >
                    <div className='main-content'>
                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'><a href={HOME_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}</a></li>
                                    <li className='breadcrumb-item'><a href={SEARCH_ALL_LICENCE_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION)}</a></li>
                                    <li className='breadcrumb-item active' aria-current='page'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORSERVICE)}</li>
                                </ol>
                            </div>
                        </nav>
                        <ScrollToTop />

                        <Layout type={LAYOUT_TYPE.FORM} footer>
                            <SMARTForm
                                className='container'
                                formContext={FORM_CONTEXT}
                                sectionNames={formNames}
                                defaultSection={Object.keys(formNames)[0]}
                                formValues={this.state.values}
                                validationSchema={this.state.validationSchema}
                                draftValidationSchema={this.state.draftValidationSchema}
                                validateOnSubmit={true}
                                navigateURL={NAVIGATE_URL}
                                nextURL={CREATE_FORM_URL}
                                serverURL={CREATE_FORM_URL}
                                draftServerURL={DRAFT_URL}
                                formIODataArray={this.state.formIODataArrayConverted}
                                formIO_SectionName={this.state.formIO_SectionName}
                            >
                                {
                                    ({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values,
                                        submitForm, submitDraft, errors, currentStep, prevStep, nextStep, modalError, modalStateError
                                    }) => {
                                        const smartFormValues = {
                                            toggleSection,
                                            toggleAllSections,
                                            values,
                                            onChange,
                                            onChangeField,
                                            errors,
                                            modalError,
                                            modalStateError,
                                            validationSchema: this.state.validationSchema
                                        };
                                        return (
                                            <React.Fragment>
                                                <Col>
                                                    <SectionWrapper
                                                        type={SECTION_LAYOUT_TYPE}
                                                        tabs={formNames}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}>
                                                        {/*General Information Section*/}
                                                        {this.state.devData.GeneralDefinition !== undefined &&
                                                            <Section
                                                                className='completed'
                                                                type={SECTION_LAYOUT_TYPE}
                                                                sectionState={sectionState}
                                                                toggleSection={toggleSection}
                                                                sectionName={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                                key={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                            >
                                                                <GeneralInformationComponentInput
                                                                    smartFormValues={smartFormValues}
                                                                    state={this.state}
                                                                />
                                                            </Section>

                                                        }

                                                        {/* Dynamically Generated Form Fields */}

                                                        {/*Common Licence Section*/}
                                                        {commonFieldsExist &&
                                                            <Section
                                                                type={SECTION_LAYOUT_TYPE}
                                                                sectionState={sectionState}
                                                                toggleSection={toggleSection}
                                                                sectionName={t(WIZARD_TAB_NAMES.COMMON_INFORMATION)}
                                                                key={t(WIZARD_TAB_NAMES.COMMON_INFORMATION)}
                                                            >
                                                                {!this.state.isUsingFormIO && <CommonInformation
                                                                    smartFormValues={smartFormValues}
                                                                    form={this.state.devData.CommonDefinitions.FieldsDefinition}
                                                                    optionDataList={optionDataList}
                                                                />}
                                                                {this.state.isUsingFormIO && <>
                                                                    <div className="heading-bg" key={t(WIZARD_TAB_NAMES.COMMON_INFORMATION)}>{t(WIZARD_TAB_NAMES.COMMON_INFORMATION)}</div>
                                                                    <div className="collapse-expand">
                                                                        <Button color="toggle" className="toggle-all" onClick={() => this.formIO_CollapseButtonClickHandler(true, -1)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                                        <Button color="toggle" className="toggle-all" onClick={() => this.formIO_CollapseButtonClickHandler(false, -1)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                                                    </div>
                                                                    <Form form={this.state.commonFormIOJson} formReady={(form) => this.formIO_ReadyHandler(form, -1)}
                                                                        onChange={(formData) => this.formIO_CommonInformation_ChangeHandler(formData)}
                                                                    />
                                                                </>}
                                                            </Section>
                                                        }

                                                        {/* Licences Generation Code */}
                                                        {this.state.devData.FormDefinitions.map((form, i) =>
                                                            <Section
                                                                ref={(section) => this.formIOSectionRef = section}
                                                                type={SECTION_LAYOUT_TYPE}
                                                                sectionState={sectionState}
                                                                toggleSection={toggleSection}
                                                                sectionName={formLanguageKeys[i]}
                                                                key={formLanguageKeys[i]}>
                                                                {!this.state.isUsingFormIO && <LicenceInformation
                                                                    smartFormValues={smartFormValues}
                                                                    key={form.FormType}
                                                                    form={form}
                                                                    formName={formLanguageKeys[i]}
                                                                    optionDataList={optionDataList}
                                                                />}
                                                                {this.state.isUsingFormIO && <>
                                                                    <div className="heading-bg" key={this.state.formIOFormsList[i].name}>{this.state.formIOFormsList[i].name}</div>
                                                                    <div className="collapse-expand">
                                                                        <Button color="toggle" className="toggle-all" onClick={() => this.formIO_CollapseButtonClickHandler(true, i)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                                        <Button color="toggle" className="toggle-all" onClick={() => this.formIO_CollapseButtonClickHandler(false, i)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                                                    </div>
                                                                    <Form formReady={(form) => this.formIO_ReadyHandler(form, i)} url={this.state.formIOFormsList[i].url} form={this.state.formIOFormsList[i].json}
                                                                        onChange={formData => this.formIO_ChangeHandler(formData, i)} /> </>}
                                                            </Section>
                                                        )}

                                                        {/* Supporting Documents Section */}
                                                        {(FilesDefinition && FilesDefinition.Fields && FilesDefinition.Fields.length > 0) &&
                                                            <Section
                                                                type={SECTION_LAYOUT_TYPE}
                                                                sectionState={sectionState}
                                                                toggleSection={toggleSection}
                                                                sectionName={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}
                                                                key={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}>

                                                                <SupportingDocuments
                                                                    smartFormValues={smartFormValues}
                                                                    files={FilesDefinition.Fields}
                                                                />
                                                            </Section>
                                                        }

                                                        {/* Review Section */}
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}
                                                            key={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}>
                                                            <ReviewAndSubmit
                                                                isUsingFormIO={this.state.isUsingFormIO}
                                                                smartFormValues={smartFormValues}
                                                                devData={this.state.devData}
                                                                declarations={declarations}
                                                                displayDocuments={true}
                                                                formLanguageKeys={formLanguageKeys}
                                                                formIODataArray={this.state.formIODataArrayConverted}
                                                                formIOFormsList={this.state.formIOFormsList}
                                                            />
                                                        </Section>

                                                        {/* Payment Section */}
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.PAYMENTS)}
                                                            key={t(WIZARD_TAB_NAMES.PAYMENTS)}>
                                                            <PaymentSummary
                                                                licenceFees={this.state.licenceFees}
                                                                totalLicenceFee={this.state.totalLicenceFee}
                                                                choosePaymentOption={option => this.choosePaymentOption(option, values)}
                                                                onlineBtn={this.state.onlineBtn}
                                                                offlineBtn={this.state.offlineBtn}
                                                            />
                                                        </Section>
                                                    </SectionWrapper>
                                                </Col>
                                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                                    <div className='button-center'>

                                                        {/* Wizard Previous Button */}
                                                        {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== 0)) ?
                                                            <Button color='backward' size='sm' type='button' onClick={prevStep}>
                                                                {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS)}
                                                            </Button> : ''
                                                        }

                                                        {/* Cancel Button */}
                                                        {/* <Button color='backward' size='sm' type='button' onClick={() => navigateTo(CANCEL_URL)}>Cancel</Button> */}

                                                        {/* Save As Draft Button */}
                                                        <Button color='neutral' size='sm' type='button' onClick={submitDraft}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_SAVEASDRAFT)}</Button>

                                                        {/* Submit Button */}
                                                        {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep === Object.keys(this.state.formNames).length - 1)) ?
                                                            <Button color='forward' size='sm' type='button'
                                                                onClick={!this.state.isUsingFormIO ? submitForm : (this.formIO_ValidateAllForms() ? submitForm : () => this.formIO_ValidateHandler())}>
                                                                {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY)}</Button> :
                                                            ((LAYOUT_TYPE.FORM_CONTENT !== 3) &&
                                                                <Button color='forward' size='sm' type='button' onClick={submitForm}>
                                                                    {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY)}
                                                                </Button>)
                                                        }

                                                        {/* Wizard Next Button */}
                                                        {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== Object.keys(this.state.formNames).length - 1)) ?
                                                            <Button color='forward' size='sm' type='button' onClick={nextStep}>
                                                                {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_NEXT)}
                                                            </Button> : ''
                                                        }
                                                    </div>
                                                </PageFooter>
                                            </React.Fragment>
                                        );
                                    }
                                }
                            </SMARTForm>

                        </Layout>
                    </div>
                </React.Fragment >
            );
        }
    }
}

export default withTranslation()(withDynaFormWrapper(withLicenceContext(DevForm)));
