import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { TABLE_LAYOUT } from '../../../App/AppSettings';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { withModalWrapper } from '../../../Shared/Modal/ModalWrapper';
import LicenceSummaryModal from './LicenceSummaryModal';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

const MODAL_NAME_REVIEW = 'REVIEW_LICENCES';
const MODAL_NAMES = { SUBMIT: 'Submit', TablePopUp: 'TablePopUp' };

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

class LicenceSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionNames: undefined
        };
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    componentDidUpdate() {
        const { formSections, t } = this.props;
        const sectionNames = {};
        if (formSections != null && formSections != undefined) {
            formSections.forEach(formSection => {
                sectionNames[formSection.Name] = { title: t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, formSection.Name)), status: true };
            });
        }
        
        this.setState(prevState => {
            if (prevState.sectionNames === undefined) {
                return {
                    sectionNames
                };
            }
            else {
                return null;
            }
        });
    }

    handleReviewClick(modal) {
        modal.toggleModal(MODAL_NAME_REVIEW);
    };

    displaySections = (sections, valuesFormInfo, valuesCommonInfo, toggleSection) => {
        let sectionNames = this.state.sectionNames;
        const { formTranslationKey, t } = this.props;
        if ((sectionNames && Object.keys(sectionNames).length === 0) && sections != undefined && sections != null && sections.length > 0) {
            sections.forEach(section => {
                sectionNames[section.Name] = { title: t(getLangKey(LANGUAGE_KEYS.BLS_FORMIO_FIELD, formTranslationKey, section.Name)), status: true };
            })
        }
        const sectionsDisplay = [];
        if (sections != undefined && sections != null && sections.length > 0 && Object.keys(valuesFormInfo).length > 0) {
            sections.forEach((section, i) => {
                sectionNames &&
                    sectionsDisplay.push(
                        <AccordionContainer
                            key={section.Name + i}
                            className="accordion"
                            title={sectionNames && sectionNames[section.Name].title}
                            active={sectionNames && sectionNames[section.Name].status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={sectionNames}
                        >
                            {section.Fields && section.Fields.map(field => {
                                const fieldKey = Object.keys(valuesFormInfo).find(fieldKey => fieldKey === field.Name);
                                const commonFieldKey = valuesCommonInfo ? Object.keys(valuesCommonInfo).find(fieldKey => fieldKey === field.Name) : null;
                                const fieldLabel = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name)) !== "" ? t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name)) : t(getLangKey(LANGUAGE_KEYS.BLS_FORMIO_FIELD, formTranslationKey, field.Name)); 
                                if (field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                    return (
                                        <Plaintext
                                            className="modal-plaintext"
                                            key={field.Name}
                                            label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                            name={field.DisplayName}
                                            value={fieldKey ? t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, valuesFormInfo[fieldKey])) : valuesCommonInfo[commonFieldKey]}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    );
                                } else {
                                    return (
                                        field.InputType !== 'FileUpload' && <Plaintext
                                            className="modal-plaintext"
                                            key={field.Name}
                                            label={fieldLabel}
                                            name={field.DisplayName}
                                            value={fieldKey ? (valuesFormInfo ? valuesFormInfo[fieldKey] : "") : (valuesCommonInfo ? valuesCommonInfo[commonFieldKey] : "")}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    );
                                }
                            })}
                            {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                <Table className="griddle-table">
                                    <thead className="griddle-table-heading">
                                        <tr>
                                            {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                return (
                                                    <th key={findex} className="griddle-table-heading-cell">{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}</th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className="griddle-table-body">
                                        {valuesFormInfo[section.Name] && valuesFormInfo[section.Name].map((row, rindex) => {
                                            return (
                                                <tr key={rindex} className="griddle-row">
                                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                        if (field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                                            return (
                                                                <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                    <Plaintext
                                                                        className="modal-plaintext"
                                                                        key={findex + rindex}
                                                                        label={undefined}
                                                                        name={field.Name}
                                                                        value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, row[field.Name]))}
                                                                        labelSize={null}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                    {
                                                                        field.InputType !== 'FileUpload' && <Plaintext
                                                                            className="modal-plaintext"
                                                                            key={findex + rindex}
                                                                            name={field.Name}
                                                                            value={row[field.Name]}
                                                                            inputSize={INPUT_SIZE}
                                                                            label={undefined}
                                                                            labelSize={null}
                                                                        />
                                                                    }
                                                                </td>
                                                            );
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            }
                            {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                <Table className="griddle-table">
                                    <thead className="griddle-table-heading">
                                        <tr>
                                            {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                return (
                                                    field.ShowField === 'Show' && <th key={findex} className="griddle-table-heading-cell">{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}</th>
                                                );
                                            })}
                                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTION)}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="griddle-table-body">
                                        {valuesFormInfo[section.Name] && valuesFormInfo[section.Name].map((row, rindex) => {
                                            return (
                                                <tr key={rindex} className="griddle-row">
                                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                        if (field.ShowField === 'Show' && field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                                            return (
                                                                <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                    <Plaintext
                                                                        className="modal-plaintext"
                                                                        key={findex + rindex}
                                                                        label={undefined}
                                                                        name={field.Name}
                                                                        value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, row[field.Name]))}
                                                                        labelSize={null}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                field.ShowField === 'Show' && <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                    {
                                                                        field.InputType !== 'FileUpload' && <Plaintext
                                                                            className="modal-plaintext"
                                                                            key={findex + rindex}
                                                                            name={field.Name}
                                                                            value={row[field.Name]}
                                                                            inputSize={INPUT_SIZE}
                                                                            label={undefined}
                                                                            labelSize={null}
                                                                        />
                                                                    }
                                                                </td>
                                                            );
                                                        }
                                                    })}
                                                    <td className="griddle-cell table-griddle-cell">
                                                        <div className="action-button-group table-button">
                                                            <Button
                                                                className="action-btn"
                                                                color="forward"
                                                                type="button"
                                                                onClick={() => this.toggleModal(MODAL_NAMES.TablePopUp, rindex)}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        <ModalConfirm
                                            className="modal-table"
                                            isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                                            contentHeader={`View ${section.DisplayName}`}
                                            contentBody={
                                                valuesFormInfo[section.Name] && valuesFormInfo[section.Name][this.state.modalID] &&
                                                section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                    if (field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                                        return (
                                                            <td key={findex + this.state.modalID}>
                                                                <Plaintext
                                                                    className="modal-plaintext"
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, valuesFormInfo[section.Name][this.state.modalID][field.Name]))}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <div key={findex + this.state.modalID}>
                                                                {
                                                                    field.InputType !== 'FileUpload' && <Plaintext
                                                                        className="modal-plaintext"
                                                                        name={field.Name}
                                                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                        value={valuesFormInfo[section.Name][this.state.modalID][field.Name]}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                })
    
                                            }
                                            confirmText="Close"
                                            confirmCallback={() => { this.toggleModal(MODAL_NAMES.TablePopUp, this.state.modalID); }}
                                        />
                                    </tbody>
                                </Table>
                            }
                            {section.Datagrids && section.Datagrids.map(datagrid => {
                                const datagridKey = Object.keys(valuesFormInfo).find(key => key === datagrid.Name);
                                const datagridValues = valuesFormInfo[datagridKey];
                                const datagridLabel = t(getLangKey(LANGUAGE_KEYS.BLS_FORMIO_FIELD, formTranslationKey, datagrid.Name));
                                return (<div>
                                    <Plaintext
                                            className="modal-plaintext"
                                            key={datagrid.Name}
                                            label={datagridLabel}
                                            name={datagrid.DisplayName}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        {datagridValues.map(gridValue => {
                                            return (
                                                Object.keys(gridValue).map(key => {
                                                    const fieldLabel = t(getLangKey(LANGUAGE_KEYS.BLS_FORMIO_FIELD, formTranslationKey, key));
                                                    return <Plaintext
                                                        className="modal-plaintext"
                                                        key={key}
                                                        label={fieldLabel}
                                                        name={fieldLabel}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                        value={gridValue[key]}
                                                    />
                                                })
                                            );
                                        })}
                                </div>);
                            })}
                        </AccordionContainer>);
            });
        }
        
        return sectionsDisplay;
    }

    render() {
        const { modal, valuesCommonInfo, formSections, valuesFormInfo,
            documentList, formName, toggleSection, displayDocuments, t } = this.props;
        return (
            <React.Fragment>
                <div className="review-submit-blog">
                    <div className="review-header">
                        <h4>{formName}</h4>
                        <div className="review-action">
                            {/*note: cannot use <button> for some reason so color has to be passed in to get
                            rid of the default secondary color */}
                            <Button className="btn-bordered-small-review-info" color="grey"
                                onClick={() => this.handleReviewClick(modal)}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_REVIEWINFORMATION)}
                            </Button>
                            {/* <button className="btn btn-bordered-small">Edit</button> */}
                        </div>
                    </div>
                    {displayDocuments && documentList.length > 0 ?
                        <div className="review-details">
                            <h5>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_DOCUMENTSSUBMITTED)}</h5>
                            {documentList.map((doc, i) => <p key={i} className="file-pdf icons">{doc}</p>)}
                        </div>
                        : null
                    }
                </div>

                <LicenceSummaryModal
                    isOpen={modal.modalState === MODAL_NAME_REVIEW}
                    closeCallBack={() => modal.toggleModal(MODAL_NAME_REVIEW)}
                    contentHeader={this.props.formName}
                >
                    {this.displaySections(formSections, valuesFormInfo, valuesCommonInfo, toggleSection)}
                </LicenceSummaryModal>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withModalWrapper(LicenceSummary));
